import { Icon } from '@/components/Icon';
import { useClassName } from '@/styles/SliderSwiper';
import { ComponentPropsWithRef, ComponentWithRef } from '@/types/component';
import { mergeProps } from 'lib/utils/merge';
import { forwardRef } from 'react';

const arrow = <Icon name="sliderArrow" options={{ className: 'h-10 w-6.5' }} />;

export interface SwiperArrowProps extends ComponentPropsWithRef<'div'> {}

export const SwiperArrowComponent: ComponentWithRef<HTMLDivElement, SwiperArrowProps> = forwardRef(
  function SwiperArrowComponent({ children, ...props }, ref) {
    const className = useClassName('arrow', props);

    return (
      <div ref={ref} {...mergeProps({ className }, props)}>
        {children ?? arrow}
      </div>
    );
  },
);
