import { Icon, IconProps } from '@/components/Icon';
import { JwVideoTeaser, JwVideoTeaserProps } from '@/components/JwVideo/Teaser';
import { OptionalLink } from '@/components/OptionalLink';
import { SliderSlideProps } from '@/components/Slider/Slide';
import { SliderSwiper, SliderSwiperProps } from '@/components/Slider/Swiper';
import { componentTheme as jwVideoTeaserComponentTheme } from '@/styles/JwVideoTeaser';
import { ClassNameProvider, componentTheme } from '@/styles/VideoSlider';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { mergeOptions } from '@/utils/merge';
import { gridSystemBreakpoints } from 'base/configs/gridSystem';
import { Playlist } from 'lib/data/video/types';
import { ReactNode, useMemo } from 'react';
import 'swiper/css';
import { SwiperSlide } from 'swiper/react';
import { VideoSlider } from './VideoSlider';

interface ExtendedOptions {
  $swiperSlide?: SliderSlideProps['options'];
  $swiper?: SliderSwiperProps['options'];
  $teaser?: JwVideoTeaserProps['options'];
  $arrow?: IconProps['options'];
  $playIcon?: IconProps['options'];
}

export interface StandaloneVideoSliderProps extends StandaloneComponentProps<typeof VideoSlider, ExtendedOptions> {
  slides?: (Playlist & { isImage?: boolean })[];
  title?: ReactNode;
  description?: ReactNode;
  url?: string;
}

export const StandaloneVideoSlider: StandaloneComponent<StandaloneVideoSliderProps> = ({
  slides = [],
  title,
  description,
  url,
  options,
  ...props
}) => {
  const { $header: headerProps, $swiper, $swiperSlide, $teaser, $arrow, $playIcon, ...$base } = options ?? {};
  const {
    $arrow: $headerArrow,
    $title: $headerTitle,
    $description: $headerDescription,
    ...$header
  } = headerProps ?? {};

  const arrow = <Icon name="sliderForwardArrow" options={mergeOptions({ size: 'none' }, $arrow)} />;

  const playIcon = (
    <Icon name="play" options={mergeOptions({ size: 'large', className: '[&_path]:fill-white' }, $playIcon)} />
  );

  const slideItems = useMemo(
    () =>
      slides?.map((item) => (
        <SwiperSlide key={item.mediaId} {...$swiperSlide}>
          <JwVideoTeaser
            mediaId={item.mediaId}
            thumbnail={item.image}
            title={item.title}
            teaserLink={item.url}
            tag={item.prominentTag}
            playIcon={playIcon}
            isStillImage={Boolean(item.isImage)}
            options={mergeOptions(
              {
                theme: jwVideoTeaserComponentTheme('video_pages'),
                variant: 'vertical',
                $link: { target: '_self' },
                $media: {
                  $video: { playOnHover: true, size: 'horizontal' },
                  $image: {
                    src: item.image,
                    size: 'vertical',
                  },
                },
                $content: {
                  colors: 'default',
                },
              } as JwVideoTeaserProps['options'],
              $teaser,
            )}
          />
        </SwiperSlide>
      )),
    [],
  );

  return (
    <ClassNameProvider value={$base}>
      <VideoSlider {...$base} {...props}>
        {title && (
          <OptionalLink
            href={url}
            content={
              <VideoSlider.Header {...$header}>
                <VideoSlider.Header.Title {...$headerTitle}>
                  {title}
                  <VideoSlider.Header.Arrow options={$headerArrow} />
                </VideoSlider.Header.Title>
                {description && (
                  <VideoSlider.Header.Description {...$headerDescription}>{description}</VideoSlider.Header.Description>
                )}
              </VideoSlider.Header>
            }
          />
        )}
        <SliderSwiper
          content={slideItems}
          hideNavigation
          arrow={arrow}
          options={mergeOptions(
            {
              theme: componentTheme('sliderSwiper'),
              speed: 300,
              autoplay: false,
              disabledClass: true,
              loop: false,
              spaceBetween: 12,
              breakpoints: {
                [gridSystemBreakpoints.xs]: {
                  slidesPerView: 2.2,
                },
                [gridSystemBreakpoints.md]: {
                  slidesPerView: 3,
                },
                [gridSystemBreakpoints.lg]: {
                  slidesPerView: 4,
                },
              },
            },
            $swiper,
          )}
        />
      </VideoSlider>
    </ClassNameProvider>
  );
};

export default StandaloneVideoSlider;
