import { Icon, IconProps } from '@/components/Icon';
import { JwVideoArticle, JwVideoArticleProps } from '@/components/JwVideo/Article';
import { Link, LinkProps } from '@/components/Link';
import { Tag, TagProps } from '@/components/Tag';
import { VideoTranscript, VideoTranscriptProps } from '@/components/VideoTranscript';
import { ClassNameProvider, componentTheme } from '@/styles/JwVideoVideo';
import type { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { mergeOptions, mergeProps } from '@/utils/merge';
import { omit } from 'lodash-es';
import { ReactNode, useRef, useState } from 'react';
import { isTruthy } from 'typesafe-utils';
import { Video } from './Video';

export interface StandaloneVideoProps
  extends StandaloneComponentProps<
    typeof Video,
    {
      $actions?: {
        $transcript?: {
          $arrow?: IconProps['options'];
        };
      };
    }
  > {
  prominentTagLink?: LinkProps;
  video?: JwVideoArticleProps;
  prominentTag?: ReactNode;
  title?: ReactNode;
  description?: ReactNode;
  tags?: TagProps[];
  share?: ReactNode;
  transcript?: VideoTranscriptProps;
}

export const StandaloneJwVideoVideo: StandaloneComponent<StandaloneVideoProps> = ({
  video,
  prominentTag,
  title,
  description,
  tags,
  share,
  transcript,
  prominentTagLink,
  options,
  ...props
}) => {
  const actionsModalRef = useRef<HTMLDialogElement>(null);
  const [isTranscriptionOpen, setIsTranscriptionOpen] = useState(false);

  const { $tags, $actions: actionsProps, $description, $group, $title, $prominentTag, ...$base } = options ?? {};
  const {
    $share: $actionsShare,
    $transcript: actionsTranscriptProps,
    $modal: $actionsModal,
    ...$actions
  } = actionsProps ?? {};
  const { $arrow: $actionsTranscriptArrow, ...$actionsTranscript } = actionsTranscriptProps ?? {};

  return (
    <ClassNameProvider value={omit($base, ['variant'])}>
      <Video {...$base} {...props}>
        <JwVideoArticle
          hideNextVideoPopup={true}
          {...mergeProps({ options: { theme: componentTheme('jwVideoArticle') } }, video)}
        />

        <Video.Group {...$group}>
          {prominentTag && (
            <Link
              content={<Video.ProminentTag {...$prominentTag}>{prominentTag}</Video.ProminentTag>}
              {...prominentTagLink}
            />
          )}
          <Video.Title {...$title}>{title}</Video.Title>
          <Video.Description {...$description}>{description}</Video.Description>

          {isTruthy(tags?.length) && (
            <Video.Tags {...$tags}>
              {tags.map((tag, index) => (
                <Tag key={index} {...mergeProps({ options: { size: 'small' } }, tag)} />
              ))}
            </Video.Tags>
          )}

          {[share, transcript?.transcription?.length].some(Boolean) && (
            <Video.Actions {...$actions}>
              {share && (
                <Video.Actions.Share onClick={() => actionsModalRef.current?.showModal()} {...$actionsShare}>
                  {share}
                </Video.Actions.Share>
              )}

              {isTruthy(transcript?.transcription?.length) && (
                <Video.Actions.Transcript
                  onClick={() => setIsTranscriptionOpen((state) => !state)}
                  {...$actionsTranscript}
                >
                  Se transkribering
                  <Icon
                    name="teaserArrow"
                    data-open={isTranscriptionOpen ? '' : undefined}
                    options={mergeOptions(
                      {
                        className: `rotate-90 data-[open]:rotate-270 h-3 mt-0.5`,
                      },
                      $actionsTranscriptArrow,
                    )}
                  />
                </Video.Actions.Transcript>
              )}
            </Video.Actions>
          )}
        </Video.Group>

        <VideoTranscript
          {...mergeProps(
            {
              'data-open': isTranscriptionOpen ? '' : undefined,
              title: 'Transkribering',
            },
            transcript,
          )}
        />
      </Video>

      <Video.Actions.Modal ref={actionsModalRef} onClick={() => actionsModalRef.current?.close()} {...$actionsModal} />
    </ClassNameProvider>
  );
};
export default StandaloneJwVideoVideo;
